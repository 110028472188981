/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: febrero 2024                                                    ###### 
##################################################################################*/

const DocumentManagementMain = () => import('../DocumentManagementMain.vue');
const BandejaExpedientes = () => import('../BandejaExpedientes.vue');

import { Role } from "@/router/role.js";


const documentManagementRoutes = [
  {
    path: 'document-management',
    name: 'modules.pharmaceuticalServicesMain.documentManagement',
    component: DocumentManagementMain,
    children: [
      {
        path: 'bandeja-expedientes',
        name: 'modules.pharmaceuticalServicesMain.documentManagement.bandejaExpedientes',
        component: BandejaExpedientes,
        meta: { authorize: Role.ServiciosFarmaceuticos_GestionDocumental && Role.ServiciosFarmaceuticos_GestionDocumental_BandejaExpedientes }
      },

    ],
    meta: { authorize: Role.ServiciosFarmaceuticos_GestionDocumental }
  },
];


export default documentManagementRoutes;