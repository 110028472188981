/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Estefania Villada Chavarria                                   ###### 
###### @date: Abril 2024                                                      ###### 
##################################################################################*/

import { Role } from "@/router/role.js";
import competencyModelRoutes from './competency-model/routes/competency-model.routes';
import performanceEvaluationRoutes from './performance-evaluation/routes/performance-evaluation.routes.js';
import structureRoutes from './structure/routes/structure.routes.js';
const HumanTalent = () => import('./HumanTalent.vue');

const humanTalentRoutes = [
  {
    path: '/modules/human-talent',
    name: 'modules.humanTalent',
    component: HumanTalent,
    children: [
      ...competencyModelRoutes,
      ...performanceEvaluationRoutes,
      ...structureRoutes,
    ],
    meta: { authorize: Role.TalentoHumano }
  },
];

export default humanTalentRoutes;