/*################################################################################## 
###### HERINCO                                                                ###### 
###### @author: Oscar Andrés Ochoa Villegas                                   ###### 
###### @date: Enero 2022                                                      ###### 
##################################################################################*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/modules/Home.vue'
import Enterprise from '../views/Enterprise.vue'

import settingsRoutes from '../views/modules/settings/settings.routes.js';
import pharmaceuticalServicesMainRoutes from '../views/modules/pharmaceutical-services/pharmaceuticalServicesMain.routes.js';
import expedienteMainRoutes from '../views/modules/expediente/expedienteMain.routes.js';
import toolsRoutes from '../views/modules/tools/tools.routes.js';
import analyticsRoutes from '../views/modules/analytics/analytics.routes.js';
import logisticsRoutes from '../views/modules/logistics/logistics.routes.js';
import reportsRoutes from '../views/modules/reports/reports.routes.js';
import profileRoutes from '../views/modules/profile/profile.routes.js';
import helpRoutes from '../views/modules/help/help.routes.js';
import exampleRoutes from '../views/modules/example/example.routes.js';
import validatorMeshMainRoutes from '../views/modules/validator-mesh/validatorMeshMain.routes.js';

import humanTalentRoutes from '../views/modules/human-talent/humanTalent.routes.js';

Vue.use(VueRouter)

/**
 * Rutas usadas por el sistema de navegación de VUE
 */
const routes = [
  {
    path: '*',
    component: Enterprise,
  },
  {
    path: '/modules/home',
    name: 'modules.home',
    component: Home,
    meta: { authorize: [] }
  },
  {
    path: '/modules/enterprise',
    name: 'modules.enterprise',
    component: Enterprise,
    meta: { authorize: [] }
  },
  
  ...settingsRoutes,
  ...expedienteMainRoutes,
  ...pharmaceuticalServicesMainRoutes,
  ...toolsRoutes,
  ...analyticsRoutes,
  ...logisticsRoutes,
  ...reportsRoutes,

  ...profileRoutes,
  ...helpRoutes,
  ...exampleRoutes,
  ...validatorMeshMainRoutes,
  ...humanTalentRoutes,
]

/**
 * Construcción de rutas
 */
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

/**
 * Restricciones de seguridad de roles para las diferentes vistas
 */
router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page

  const vuexState = JSON.parse(localStorage.getItem('vuex'));
  if (vuexState) {
    store.state.auth.roles=vuexState.auth.roles;
  }
  next();

  // console.log(to, from, next);
  // console.log("store", store);
  
  const { authorize } = to.meta;
  let userRoles = store.state.auth.roles;
  
  // console.log("authorize", authorize);
  // console.log("userRoles", userRoles);
  console.log("store", store);

  // Si se solicita la raíz se redirecciona al home
  if (to.path === '/') {
    next({ path: "/modules/enterprise" });
  }

  // En caso de no tener roles por navegacion forzada setearlos a vacio
  if (userRoles === undefined) {
    userRoles = [];
  }

  if (authorize) {
    // check if route is restricted by role
    const hasAccess = Array.isArray(authorize)
      ? authorize.some(role => userRoles.includes(role))
      : userRoles.includes(authorize);

    if (authorize.length && !hasAccess) {
      // role not authorised so redirect to home page
      return next({ path: "/modules/enterprise" });
    }
  }
  
  next();
});

export default router