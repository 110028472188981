/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Estefania Villada Chavarria                                   ###### 
###### @date: Abril 2024                                                      ###### 
##################################################################################*/
const CompetencyModel = () => import('../CompetencyModel.vue')
const Competencia = () => import('../Competencia.vue')
const Habilidades = () => import('../Habilidades.vue')
import { Role } from "@/router/role.js";

const competencyModelRoutes = [
  {
    path: 'competency-model',
    name: 'modules.humanTalent.competencyModel',
    component: CompetencyModel,
    children: [
      {
        path: 'competencia',
        name: 'modules.humanTalent.competencyModel.competencia',
        component: Competencia,
        meta: { authorize: Role.TalentoHumano_ModeloCompetencias && Role.TalentoHumano_ModeloCompetencias_Competencia }
      },
      {
        path: 'habilidades',
        name: 'modules.humanTalent.competencyModel.habilidades',
        component: Habilidades,
        meta: { authorize: Role.TalentoHumano_ModeloCompetencias && Role.TalentoHumano_ModeloCompetencias_Habilidades }
      },
    ],
    meta: { authorize: Role.TalentoHumano_ModeloCompetencias }
  },
];

export default competencyModelRoutes;