/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Cristian Valencia Agudelo                                     ###### 
###### @date: Enero 2024                                                      ###### 
##################################################################################*/

import { Role } from "@/router/role.js";

// import expedienteRoutes from './expedientes/expediente/routes/expediente.routes.js';
// import documentManagementRoutes from "./document-management/routes/documentManagement.routes.js";

const ExpedienteServicesMain = () => import('./expedienteServicesMain.vue');
const Formulas = () => import('./formulas/Formulas.vue');
const Digitalizador = () => import('./digitalizador/Digitalizador.vue');
const Expedientes = () => import('./expedientes/Expedientes.vue');
const Expediente = () => import('./expediente/Expediente.vue');
const Parametrizacion = () => import('./parametrizacion/Parametrizacion.vue');

// const ExpedientesServicesMain = () => import('./ExpedientesServicesMain.vue');

const expedienteMainRoutes = [
  {
    path: '/modules/expediente',
    name: 'modules.expedienteMain',
    component: ExpedienteServicesMain,
    children: [
      {
        path: 'bandeja',
        name: 'modules.expedienteMain.bandeja',
        component: Formulas,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Digitalizador] }
      },
      {
        path: 'digitalizador',
        name: 'modules.expedienteMain.digitalizador',
        component: Digitalizador,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Digitalizador] }
      },
      {
        path: 'expedientes',
        name: 'modules.expedienteMain.expedientes',
        component: Expedientes,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Auditor, Role.Expediente_Radicador] }
      },
      {
        path: 'expediente/:id',
        name: 'modules.expedienteMain.expediente',
        component: Expediente,
        meta: { authorize: [Role.Expediente_Administrador, Role.Expediente_Auditor, Role.Expediente_Radicador] }
      },
      {
        path: 'parametrizacion',
        name: 'modules.expedienteMain.parametrizacion',
        component: Parametrizacion,
        meta: { authorize: Role.Expediente_Administrador }
      }
    ],
    meta: { authorize: Role.Expediente }
  }
  // ,{
  //   path: '/modules/expedientes',
  //   name: 'modules.expedientesServicesMain',
  //   component: ExpedientesServicesMain,
  //   children: [
  //     // ...digitalizadorRoutes,
  //     // ...expedienteRoutes

  //   ],
  //   meta: { authorize: Role.ServiciosFarmaceuticos }
  // },
];


export default expedienteMainRoutes;