/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Estefania Villada Chavarria                                   ###### 
###### @date: Junio 2024                                                      ###### 
##################################################################################*/
const Structure = () => import('../Structure.vue');
const GrupoProceso = () => import('../GrupoProceso.vue');
const Ubicaciones = () => import('../Ubicaciones.vue');
const Cargos = () => import('../Cargos.vue');
const NivelesJerarquicos = () => import('../NivelesJerarquicos.vue')
const Colaboradores  = () => import('../Colaboradores.vue')
const Contratos = () => import('../Contratos.vue')
const Procesos =  () => import('../Procesos.vue')
const TiposContratos =  () => import('../TiposContratos.vue')
const LiderGrupo =  () => import('../LiderGrupo.vue')
import { Role } from "@/router/role.js";

const structureRoutes = [
  {
    path: 'structure',
    name: 'modules.humanTalent.structure',
    component: Structure,
    children: [
      {
        path: 'grupo-proceso',
        name: 'modules.humanTalent.structure.grupoProceso',
        component: GrupoProceso,
        meta: { authorize: Role.TalentoHumano_Estructura && Role.TalentoHumano_Estructura_GrupoProceso }
      },
      {
        path: 'ubicaciones',
        name: 'modules.humanTalent.structure.ubicaciones',
        component: Ubicaciones,
        meta: { authorize: Role.TalentoHumano_Estructura && Role.TalentoHumano_Estructura_Ubicaciones }
      },
      {
        path: 'cargos',
        name: 'modules.humanTalent.structure.cargos',
        component: Cargos,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Estructura_Cargos }
      },
      {
        path: 'niveles-jerarquicos',
        name: 'modules.humanTalent.structure.nivelesJerarquicos',
        component: NivelesJerarquicos,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Estructura_NivelesJerarquicos }
      },
      {
        path: 'colaboradores',
        name: 'modules.humanTalent.structure.colaboradores',
        component: Colaboradores,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Colaboradores }
      },
      {
        path: 'contratos',
        name: 'modules.humanTalent.structure.contratos',
        component: Contratos,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Estructura_Contratos }
      },
      {
        path: 'procesos',
        name: 'modules.humanTalent.structure.procesos',
        component: Procesos,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Estructura_Procesos }
      },
      {
        path: 'tipos-contratos',
        name: 'modules.humanTalent.structure.tiposContratos',
        component: TiposContratos,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Estructura_TiposContratos }
      },
      {
        path: 'lider-grupo',
        name: 'modules.humanTalent.structure.liderGrupo',
        component: LiderGrupo,
        meta: { authorize: Role.TalentoHumano_Estructura  && Role.TalentoHumano_Estructura_LiderGrupo }
      },
    ],
    meta: { authorize: Role.TalentoHumano_Estructura }
  },
];

export default structureRoutes;