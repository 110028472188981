/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "@/router/role.js";
import dispensationRoutes from './dispensation/routes/dispensation.routes.js';
import documentManagementRoutes from "./document-management/routes/documentManagement.routes.js";

// import expedienteRoutes from './expedientes/expediente/routes/expediente.routes.js';
// import documentManagementRoutes from "./document-management/routes/documentManagement.routes.js";

const PharmaceuticalServicesMain = () => import('./PharmaceuticalServicesMain.vue');
// const ExpedientesServicesMain = () => import('./ExpedientesServicesMain.vue');

const pharmaceuticalServicesMainRoutes = [
  {
    path: '/modules/pharmaceutical-services',
    name: 'modules.pharmaceuticalServicesMain',
    component: PharmaceuticalServicesMain,
    children: [
      ...dispensationRoutes,
      ...documentManagementRoutes

    ],
    meta: { authorize: Role.ServiciosFarmaceuticos }
  }
  // ,{
  //   path: '/modules/expedientes',
  //   name: 'modules.expedientesServicesMain',
  //   component: ExpedientesServicesMain,
  //   children: [
  //     // ...digitalizadorRoutes,
  //     // ...expedienteRoutes

  //   ],
  //   meta: { authorize: Role.ServiciosFarmaceuticos }
  // },
];


export default pharmaceuticalServicesMainRoutes;