/*##################################################################################
###### HERINCO                                                                ######
###### @author: John David Vásquez Serna                                      ######
###### @date: Enero 2025                                                      ######
##################################################################################*/

const Billing = () => import('../Billing.vue');
const RadicacionFactura = () => import('../RadicacionFactura.vue');

import { Role } from "@/router/role.js";

const billingRoutes = [
  {
    path: 'billing',
    name: 'modules.reports.billing',
    component: Billing,
    children: [
      {
        path: 'radicacionFactura',
        name: 'modules.reports.billing.radicacionFactura',
        component: RadicacionFactura,
        meta: { authorize: Role.billingRoutes && Role.Reportes_Facturacion_Radicacion }
      },
    ],
    meta: { authorize: Role.billingRoutes }
  },

];

export default billingRoutes;
