/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Cristian Valencia Agudelo                                     ###### 
###### @date: Enero 2025                                                      ###### 
##################################################################################*/

const CartasAgotado = () => import('./CartaAgotadoMain.vue');
const Cartas = () => import('./cartas.vue');
const Carta = () => import('./carta.vue');

import { Role } from "@/router/role.js";


const cartasRoutes = [
  {
    path: 'carta-agotado',
    name: 'modules.settings.cartaAgotado',
    component: CartasAgotado,
    children: [
      {
        path: 'cartas',
        name: 'modules.settings.cartaAgotado.cartas',
        component: Cartas,
        meta: { authorize: Role.Configuraciones_CartaAgotado && Role.Configuraciones_CartaAgotado_Admin }
      },
      {
        path: 'new',
        name: 'modules.settings.cartaAgotado.new',
        component: Carta,
        meta: { authorize: Role.Configuraciones_CartaAgotado && Role.Configuraciones_CartaAgotado_Admin }
      },
      {
        path: 'edit/:id',
        name: 'modules.settings.cartaAgotado.edit',
        component: Carta,
        meta: { authorize: Role.Configuraciones_CartaAgotado && Role.Configuraciones_CartaAgotado_Admin }
      }
    ],
    meta: { authorize: Role.Configuraciones_CartaAgotado }
  },
];


export default cartasRoutes;