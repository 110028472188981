/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import store from '../../../store'
import { Role } from "@/router/role.js";
import systemRoutes from './system/routes/system.routes.js';
import pharmaceutilcalServiceRoutes from './pharmaceutical-services/routes/pharmaceutilcalService.routes.js';
import administrativeRoutes from "./administrative/routes/administrative.routes.js";
import cartasRoutes from "./carta-agotado/cartas.routes.js";
const Settings = () => import('./Settings.vue');

const u = () => {
  const enterprise = store.state.enterprise.abbreviation;
  console.log(enterprise);
}
u()
const settingsRoutes = [
  {
    path: `/modules/settings`,
    name: 'modules.settings',
    component: Settings,
    children: [
      ...systemRoutes,
      ...pharmaceutilcalServiceRoutes,
      ...administrativeRoutes,
      ...cartasRoutes
    ],
    meta: { authorize: Role.Configuraciones },
    // beforeEnter: (to, from, next) => {
    //   const enterprise = store.state.enterprise.abbreviation;
    //   store.dispatch('loadEnterpriseData', enterprise).then(() => {
    //     console.log(to);
    //     next(`/modules/${enterprise}/settings`);
    //   });
    // },
  }
];


export default settingsRoutes;