/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Estefania Villada Chavarria                                   ###### 
###### @date: Abril 2024                                                      ###### 
##################################################################################*/
const PerformanceEvaluation = () => import('../PerformanceEvaluation.vue')
const Evaluaciones = () => import('../Evaluaciones.vue');
const GrupoPreguntas = () => import('../GrupoPreguntas.vue');
const Datos = () => import('../Datos.vue')
const Nominaciones = () => import('../Nominaciones.vue')
const Reemplazos = () => import('../Reemplazos.vue')
const TiposEvaluacion = () => import('../TiposEvaluacion.vue')

import { Role } from "@/router/role.js";

const performanceEvaluationRoutes = [
  {
    path: 'performance-evaluation',
    name: 'modules.humanTalent.performanceEvaluation',
    component: PerformanceEvaluation,
    children: [
      {
        path: 'evaluaciones',
        name: 'modules.humanTalent.performanceEvaluation.evaluaciones',
        component: Evaluaciones,
        meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno && Role.TalentoHumano_EvaluacionDesempeno_Evaluaciones }
      },
      {
        path: 'datos',
        name: 'modules.humanTalent.performanceEvaluation.datos',
        component: Datos,
        meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno && Role.TalentoHumano_EvaluacionDesempeno_Datos }
      },
      {
        path: 'grupo-preguntas',
        name: 'modules.humanTalent.performanceEvaluation.grupoPreguntas',
        component: GrupoPreguntas,
        meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno && Role.TalentoHumano_EvaluacionDesempeno_GrupoPreguntas }
      },
      {
        path: 'nominaciones',
        name: 'modules.humanTalent.performanceEvaluation.nominaciones',
        component: Nominaciones,
        meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno && Role.TalentoHumano_EvaluacionDesempeno_Nominaciones }
      },
      {
        path: 'reemplazo',
        name: 'modules.humanTalent.performanceEvaluation.reemplazos',
        component: Reemplazos,
        meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno && Role.TalentoHumano_EvaluacionDesempeno_Reemplazos}
      },
      {
        path: 'tipos-evaluacion',
        name: 'modules.humanTalent.performanceEvaluation.tiposEvaluacion',
        component: TiposEvaluacion,
        meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno && Role.TalentoHumano_EvaluacionDesempeno_TiposEvaluacion }
      },
    ],
    meta: { authorize: Role.TalentoHumano_EvaluacionDesempeno }
  },
];

export default performanceEvaluationRoutes;